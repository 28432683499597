import React from 'react';
import PropTypes from 'prop-types';
import './AppBenefits.scss';

const AppBenefits = ({ image, text }) => {
  return (
    <div className="app-benefits">
      <img alt="" src={image} />
      <p>{text}</p>
    </div>
  );
};

AppBenefits.defaultProps = {
  image: '',
  text: '',
};

AppBenefits.propTypes = {
  image: PropTypes.string,
  text: PropTypes.string,
};

export default AppBenefits;
