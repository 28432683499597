import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import Layout from '../components/Layout';
import SEO from '../components/SEO/SEO';

import notificationsGifEn from '../assets/images/Notify-EN-lg.gif';
import transactionsGifEn from '../assets/images/Home-EN-lg.gif';
import creditkeeperGifEn from '../assets/images/CK-lg.gif';
import notificationsGifFr from '../assets/images/Notify-FR-lg.gif';
import transactionsGifFr from '../assets/images/Home-FR-lg.gif';

import notificationsPlaceholderEn from '../assets/images/Notify-EN-lg.png';
import transactionsPlaceholderEn from '../assets/images/Home-EN-lg.png';
import creditkeeperPlaceholderEn from '../assets/images/CK-lg.png';
import notificationsPlaceholderFr from '../assets/images/Notify-FR-lg.png';
import transactionsPlaceholderFr from '../assets/images/Home-FR-lg.png';

import Jumbotron from '../components/Jumbotron/Jumbotron';
import MobileGifBox from '../components/MobileApp/MobileGifBox';
import AppPageLegalModalContent from '../components/ModalContent/AppPageLegalModalContent';
import ModalLink from '@cof/graffiti-alley-spray-cans/molecules/ModalLink';

import AppReviews from '../components/AppReviews';
import AppBenefitsWrapper from '../components/AppBenefitsWrapper';
import AppDownload from '../components/AppDownload';

import './app.scss';

const AppPage = injectIntl(({ intl }) => {
  let notificationsGif, transactionsGif, notificationsPlaceholder, transactionsPlaceholder;
  if (intl.locale === 'fr') {
    notificationsGif = notificationsGifFr;
    transactionsGif = transactionsGifFr;
    notificationsPlaceholder = notificationsPlaceholderFr;
    transactionsPlaceholder = transactionsPlaceholderFr;
  } else {
    notificationsGif = notificationsGifEn;
    transactionsGif = transactionsGifEn;
    notificationsPlaceholder = notificationsPlaceholderEn;
    transactionsPlaceholder = transactionsPlaceholderEn;
  }

  return (
    <Layout pageName="mobile-app-page" className="mobile-app-page">
      <div id="mobile-app-page-wrapper">
        <Jumbotron customJumbotronImageAlt={intl.formatMessage({ id: 'pages.app.refresh.jumbotron-image-alt' })} />
        <section className="app-gif-wrapper">
          <MobileGifBox gifSrc={notificationsGif} placeholderSrc={notificationsPlaceholder} gifId={'notifications'} />
          <MobileGifBox
            gifSrc={transactionsGif}
            placeholderSrc={transactionsPlaceholder}
            gifId={'transactions'}
            gifOrientation={'right'}
          />
          {intl.locale === 'en' && (
            <MobileGifBox
              gifSrc={creditkeeperGifEn}
              placeholderSrc={creditkeeperPlaceholderEn}
              gifId={'creditkeeper'}
            />
          )}
        </section>
        <AppBenefitsWrapper />
        <AppReviews />
        <AppDownload />
        <section className="app-legal-modal">
          <ModalLink
            linkIcon={false}
            target={() => <AppPageLegalModalContent intl={intl} />}
            className="app-legal-modal-link"
            title=" "
            intl={intl}
            size="large"
          >
            <FormattedMessage id="navfooter.legal.legal" />
          </ModalLink>
        </section>
      </div>
    </Layout>
  );
});

export default AppPage;

/* eslint-disable react/prop-types */
export const Head = ({ pageContext }) => (
  <SEO titleKey="pages.app.page-title" descriptionKey="pages.app.page-desc" intl={pageContext.intl} />
);
/* eslint-enable react/prop-types */
