import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import TextBlock from '@cof/graffiti-alley-spray-cans/molecules/TextBlock';
import Link from '@cof/graffiti-alley-spray-cans/atoms/Link';
import QRCodeButton from '../QRCodeButton';
import './Jumbotron.scss';
import appleStoreIconEn from '../../assets/images/app-refresh-apple-store-en.svg';
import appleStoreIconFr from '../../assets/images/app-refresh-apple-store-fr.svg';
import googleStoreIconEn from '../../assets/images/app-refresh-google-store-en.svg';
import googleStoreIconFr from '../../assets/images/app-refresh-google-store-fr.svg';
import qrCodeIconEn from '../../assets/images/app-refresh-qr-code-en.svg';
import qrCodeIconFr from '../../assets/images/app-refresh-qr-code-fr.svg';
import JumbotronMobileImage from '../../assets/images/desktop-hero-en.svg';
import JumbotronMobileImageFr from '../../assets/images/desktop-hero-fr.svg';
import { useWindowWidthSize } from '../../hooks/useWindowWidthSize';
import AnimationWrapper from '@cof/graffiti-alley-spray-cans/molecules/AnimationWrapper';
import newrelicPageAction from '../../lib/newrelicSpa';

const DefaultJumbotronBackgronud = ({ intl }) => {
  const width = useWindowWidthSize(1440);
  // Ultra wide screen
  if (width > 2250) {
    return (
      <svg
        id="jumbotron-background-desktop-ultra-wide"
        preserveAspectRatio="none"
        width={width}
        height="658"
        viewBox={`0 0 ${width} 658`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d={`M1229.5 606.5C1816.8 588.946 2128.6 146.352 ${width} 295.1V0H0L3 249C234.5 384.5 642.203 624.054 1229.5 606.5Z`}
          fill="url(#paint0_radial_1984_98090)"
        />
        <defs>
          <radialGradient
            id="paint0_radial_1984_98090"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(-0.00102706 497.388) rotate(-1.18677) scale(3204.69 2620.77)"
          >
            <stop stopColor="#78D0EB" />
            <stop offset="0.522454" stopColor="#78D0EB" stopOpacity="0.74" />
            <stop offset="1" stopColor="#79D0EB" />
          </radialGradient>
        </defs>
      </svg>
    );
  }

  // Wide screen that's not covered by ddesktop image
  if (width > 1830) {
    return (
      <svg
        id="jumbotron-background-desktop-wide"
        preserveAspectRatio="none"
        width={width}
        height="694"
        viewBox={`0 0 ${width} 694`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="Vector"
          d="M-2280 152.752C-1801.64 235.761 -1559.45 319.043 -1332.31 350.496C-1088.62 384.137 -862.154 357.88 -647.179 398.906C-432.205 439.932 -228.718 548.239 -84.3077 608.957C60.1026 669.675 145.436 682.803 227.487 689.368C309.538 695.932 388.308 695.932 493.333 682.803C598.359 669.675 729.641 643.419 842.872 613.88C956.103 584.342 1051.28 551.521 1153.03 523.624C1254.77 495.726 1363.08 472.752 1502.56 462.906C1642.05 453.06 1812.72 456.342 1978.46 456.342C2144.21 456.342 2305.03 453.06 2501.13 434.188C2677.4 417.231 2882.26 387.692 3480 174.359C3480 116.239 3480 58.1197 3480 0C1560 0 -360 0 -2280 0C-2280 50.8718 -2280 101.88 -2280 152.752Z"
          fill="#78D0EB"
        />
      </svg>
    );
  }

  // Desktop view image
  if (width > 1023) {
    return (
      <svg
        id="jumbotron-background-desktop"
        preserveAspectRatio="none"
        width={width}
        height="694"
        viewBox={`0 0 ${width} 694`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="Vector"
          d="M-2280 152.752C-1801.64 235.761 -1559.45 319.043 -1332.31 350.496C-1088.62 384.137 -862.154 357.88 -647.179 398.906C-432.205 439.932 -228.718 548.239 -84.3077 608.957C60.1026 669.675 145.436 682.803 227.487 689.368C309.538 695.932 388.308 695.932 493.333 682.803C598.359 669.675 729.641 643.419 842.872 613.88C956.103 584.342 1051.28 551.521 1153.03 523.624C1254.77 495.726 1363.08 472.752 1502.56 462.906C1642.05 453.06 1812.72 456.342 1978.46 456.342C2144.21 456.342 2305.03 453.06 2501.13 434.188C2677.4 417.231 2882.26 387.692 3480 174.359C3480 116.239 3480 58.1197 3480 0C1560 0 -360 0 -2280 0C-2280 50.8718 -2280 101.88 -2280 152.752Z"
          fill="#78D0EB"
        />
      </svg>
    );
  }

  // Tablet view image
  if (width > 639) {
    if (intl.locale === 'fr') {
      return (
        <svg
          id="jumbotron-background-tablet-fr"
          width={width}
          height="597"
          viewBox={`0 0 ${width} 597`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M352.099 578.356C546.67 534.93 691.533 274.971 1050 372.094V-69H-18V510.27C61.3028 585.365 157.529 621.782 352.099 578.356Z"
            fill="#78D0EB"
          />
        </svg>
      );
    }
    return (
      <svg
        id="jumbotron-background-tablet-en"
        preserveAspectRatio="none"
        width={width}
        height="509"
        viewBox={`0 0 ${width} 509`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2010_101817)">
          <path
            d="M352.099 490.356C546.67 446.93 691.533 186.971 1050 284.094V-157H-18V422.27C61.3028 497.365 157.529 533.782 352.099 490.356Z"
            fill="#78D0EB"
          />
        </g>
        <defs>
          <clipPath id="clip0_2010_101817">
            <rect width={width} height="509" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }

  // Mobile view image
  if (intl.locale === 'fr') {
    return (
      <svg
        id="jumbotron-background-mobile-fr"
        width={width}
        height="524"
        viewBox={`0 0 ${width} 524`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M379.337 499.729C632.388 443.197 820.791 104.782 1287 231.217V-343H-102V411.094C1.1382 508.854 126.286 556.262 379.337 499.729Z"
          fill="#78D0EB"
        />
      </svg>
    );
  }

  return (
    <svg
      id="jumbotron-background-mobile-en"
      preserveAspectRatio="none"
      width={width}
      height="434"
      viewBox={`0 0 ${width} 434`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2010_101826)">
        <path
          d="M379.337 409.729C632.388 353.197 820.791 14.7822 1287 141.217V-433H-102V321.094C1.1382 418.854 126.286 466.262 379.337 409.729Z"
          fill="#78D0EB"
        />
      </g>
      <defs>
        <clipPath id="clip0_2010_101826">
          <rect width={width} height="434" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

DefaultJumbotronBackgronud.propTypes = {
  intl: intlShape,
};

const Jumbotron = injectIntl(
  ({
    intl,
    renderCustomBackground,
    customJumbotronImage,
    customJumbotronImageAlt,
    customTextBlockProps,
    customQRCodeButtonProps,
  }) => {
    let appleStoreIcon, googleStoreIcon, qrCodeIcon;
    if (intl.locale === 'fr') {
      appleStoreIcon = appleStoreIconFr;
      googleStoreIcon = googleStoreIconFr;
      qrCodeIcon = qrCodeIconFr;
    } else {
      appleStoreIcon = appleStoreIconEn;
      googleStoreIcon = googleStoreIconEn;
      qrCodeIcon = qrCodeIconEn;
    }
    return (
      <section className="jumbotron-wrapper">
        {renderCustomBackground(intl)}
        <div className="jumbotron-container">
          <div className="jumbotron-content-wrapper">
            <AnimationWrapper animation={'fadeIn'} millisecondsDuration={'5000'}>
              <TextBlock
                header={intl.formatMessage({ id: 'pages.app.refresh.jumbotron-context' })}
                titleText={intl.formatMessage({ id: 'pages.app.refresh.jumbotron-title' })}
                content={intl.formatMessage({ id: 'pages.app.refresh.jumbotron-description' })}
                {...customTextBlockProps}
              />
            </AnimationWrapper>
            <AnimationWrapper animation={'fadeIn'} millisecondsDuration={'5000'}>
              <QRCodeButton
                qrCodeImage={qrCodeIcon}
                buttonTextId="pages.app.refresh.jumbotron-button"
                {...customQRCodeButtonProps}
                onClick={() => {}}
              />
            </AnimationWrapper>
            <div className="mobile-app-download-images">
              <Link
                to={intl.formatMessage({ id: 'pages.app.refresh.mobile-store-link' })}
                className="app-store-link"
                onClick={() => newrelicPageAction('AppStoreClick')}
              >
                <img src={appleStoreIcon} className="app-store-image" alt="App Store" />
              </Link>
              <Link
                to={intl.formatMessage({ id: 'pages.app.refresh.mobile-store-link' })}
                className="google-play-link"
                onClick={() => newrelicPageAction('GooglePlayClick')}
              >
                <img src={googleStoreIcon} className="google-play-image" alt="Google Play" />
              </Link>
            </div>
          </div>
          {intl.locale === 'en' ? (
            <img className="jumbotron-image" src={customJumbotronImage} alt={customJumbotronImageAlt} />
          ) : (
            <img className="jumbotron-image" src={JumbotronMobileImageFr} alt={customJumbotronImageAlt} />
          )}
        </div>
      </section>
    );
  },
);

Jumbotron.propTypes = {
  /** Use this prop to render a custom background image with your sizing logic */
  renderCustomBackground: PropTypes.func,
  /** Use this prop to render a custom image for the right image section */
  customJumbotronImage: PropTypes.string,
  /** Use this prop to add an alt message for the custom image */
  customJumbotronImageAlt: PropTypes.string,
};

Jumbotron.defaultProps = {
  renderCustomBackground: (intl) => <DefaultJumbotronBackgronud intl={intl} />,
  customJumbotronImage: JumbotronMobileImage,
  customJumbotronImageAlt: '',
};

export default Jumbotron;
