import React from 'react';
import { injectIntl } from 'react-intl';
import ReviewCard from '../ReviewCard/ReviewCard';
import './AppReviews.scss';

const AppReviews = injectIntl(({ intl }) => {
  return (
    <div className="app-reviews-wrapper">
      <ReviewCard
        stars={5}
        text={intl.formatMessage({ id: 'pages.app.reviews.review1-text' })}
        author={intl.formatMessage({ id: 'pages.app.reviews.review1-author' })}
      />
      <ReviewCard
        stars={5}
        text={intl.formatMessage({ id: 'pages.app.reviews.review2-text' })}
        author={intl.formatMessage({ id: 'pages.app.reviews.review2-author' })}
      />
      <ReviewCard
        stars={5}
        text={intl.formatMessage({ id: 'pages.app.reviews.review3-text' })}
        author={intl.formatMessage({ id: 'pages.app.reviews.review3-author' })}
      />
    </div>
  );
});

export default AppReviews;
