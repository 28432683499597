import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import './ReviewCard.scss';

export const Star = injectIntl(({ intl, active = false, index, identifier = '' }) => {
  const starLabelId = index === 0 ? `star-${identifier.replace(/\s/g, '-').replace(/,/g, '')}-${index}` : undefined;
  return (
    <svg
      className="review-card-star"
      width="29"
      height="27"
      viewBox="0 0 29 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-active={active}
      aria-labelledby={starLabelId}
    >
      {index === 0 && <title id={starLabelId}>{intl.formatMessage({ id: 'pages.app.reviews.star-alt-text' })}</title>}
      <path d="M28.7162 10.2173L18.8565 8.77626L14.461 0L10.0535 8.77626L0.193726 10.2173L7.32268 17.1628L5.64055 26.9757L14.4543 22.3404L23.2681 26.9757L21.5859 17.1628L28.7162 10.2173Z" />
    </svg>
  );
});

Star.propTypes = {
  active: PropTypes.bool,
  index: PropTypes.number,
};

const ReviewCard = ({ stars = 5, text, author }) => (
  <div className="review-card">
    <div className="review-card-star-list">
      {[...Array(stars)].map((e, i) => (
        <Star key={i} active index={i} identifier={author} />
      ))}
      {[...Array(5 - stars)].map((e, i) => (
        <Star key={5 - stars + i} />
      ))}
    </div>
    <div className="review-card-text">{text}</div>
    <div className="review-card-author">{author}</div>
  </div>
);

ReviewCard.propTypes = {
  stars: PropTypes.number,
  text: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
};

export default ReviewCard;
