import React from 'react';
import { injectIntl } from 'react-intl';
import AppBenefits from '../AppBenefits/AppBenefits';
import fingerprintIcon from '../../assets/images/fingerprint-icon.png';
import leafIcon from '../../assets/images/leaf-icon.png';
import clockIcon from '../../assets/images/clock-icon.png';
import './AppBenefitsWrapper.scss';

const AppBenefitsWrapper = injectIntl(({ intl }) => {
  return (
    <div className="app-benefits-wrapper">
      <p className="app-benefits-title">{intl.formatMessage({ id: 'pages.app.benefits.title' })}</p>
      <div className="app-benefits-content">
        <AppBenefits image={clockIcon} text={intl.formatMessage({ id: 'pages.app.benefits.personal' })} />
        <AppBenefits image={leafIcon} text={intl.formatMessage({ id: 'pages.app.benefits.paperless' })} />
        <AppBenefits image={fingerprintIcon} text={intl.formatMessage({ id: 'pages.app.benefits.biometrics' })} />
      </div>
    </div>
  );
});

export default AppBenefitsWrapper;
