import React from 'react';
import { injectIntl } from 'react-intl';
import Link from '@cof/graffiti-alley-spray-cans/atoms/Link';
import TextBlock from '@cof/graffiti-alley-spray-cans/molecules/TextBlock';
import QRCodeButton from '../QRCodeButton';
import AnimationWrapper from '@cof/graffiti-alley-spray-cans/molecules/AnimationWrapper';
import newrelicPageAction from '../../lib/newrelicSpa';

import appleStoreIconEn from '../../assets/images/app-refresh-apple-store-en.svg';
import appleStoreIconFr from '../../assets/images/app-refresh-apple-store-fr.svg';
import googleStoreIconEn from '../../assets/images/app-refresh-google-store-en.svg';
import googleStoreIconFr from '../../assets/images/app-refresh-google-store-fr.svg';
import qrCodeIconEn from '../../assets/images/app-refresh-qr-code-en.svg';
import qrCodeIconFr from '../../assets/images/app-refresh-qr-code-fr.svg';

import './AppDownload.scss';

const AppDownload = injectIntl(({ intl }) => {
  let appleStoreIcon, googleStoreIcon, qrCodeIcon;
  if (intl.locale === 'fr') {
    appleStoreIcon = appleStoreIconFr;
    googleStoreIcon = googleStoreIconFr;
    qrCodeIcon = qrCodeIconFr;
  } else {
    appleStoreIcon = appleStoreIconEn;
    googleStoreIcon = googleStoreIconEn;
    qrCodeIcon = qrCodeIconEn;
  }
  return (
    <div className="app-download-wrapper">
      <AnimationWrapper animation={'bottomToTop'} millisecondsDuration={'2000'} threshold={0.75}>
        <TextBlock
          header={''}
          titleText={intl.formatMessage({ id: 'pages.app.download.title' })}
          content={intl.formatMessage({ id: 'pages.app.download.description' })}
        />
      </AnimationWrapper>
      <div className="mobile-app-download-images">
        <Link
          to={intl.formatMessage({ id: 'pages.app.refresh.mobile-store-link' })}
          className="app-store-link"
          onClick={() => newrelicPageAction('AppStoreClick', {})}
        >
          <img src={appleStoreIcon} className="app-store-image" alt="App Store" />
        </Link>
        <Link
          to={intl.formatMessage({ id: 'pages.app.refresh.mobile-store-link' })}
          className="google-play-link"
          onClick={() => newrelicPageAction('GooglePlayClick', {})}
        >
          <img src={googleStoreIcon} className="google-play-image" alt="Google Play" />
        </Link>
      </div>
      <div className="app-download-qr">
        <AnimationWrapper animation={'bottomToTop'} millisecondsDuration={'2000'} threshold={0.75}>
          <QRCodeButton qrCodeImage={qrCodeIcon} buttonTextId="pages.app.refresh.jumbotron-button" onClick={() => {}} />
        </AnimationWrapper>
      </div>
    </div>
  );
});

export default AppDownload;
