import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PanelDisplay from '@cof/graffiti-alley-spray-cans/molecules/PanelDisplay';
import TextBlock from '@cof/graffiti-alley-spray-cans/molecules/TextBlock';
import { superScript } from '../../lib/utils';
import './MobileApp.scss';
import AnimationWrapper from '@cof/graffiti-alley-spray-cans/molecules/AnimationWrapper';

const MobileGifBox = injectIntl(({ intl, gifSrc, placeholderSrc, gifId, gifOrientation = 'left' }) => {
  const altText = intl.formatMessage({ id: `pages.app.${gifId}.gif-alt-text` });
  return (
    <PanelDisplay
      mediaType="image"
      mediaSrc={gifSrc}
      placeHolderSrc={placeholderSrc}
      className="mobile-app-gif-content-box"
      altTitleText={altText}
      mediaOrientation={gifOrientation}
      leftPanelSize={gifOrientation === 'left' ? '5' : '7'}
    >
      <AnimationWrapper
        className="animation-wrapper"
        animation={'bottomToTop'}
        millisecondsDuration={'2000'}
        threshold={0.75}
      >
        <TextBlock
          titleHeadingTag="h2"
          header={intl.formatMessage({ id: `pages.app.${gifId}.p0` })}
          titleText={intl.formatMessage({ id: `pages.app.${gifId}.p1` })}
          content={
            <FormattedMessage
              id={`pages.app.${gifId}.p2`}
              values={{ star: superScript('star'), rball: superScript('rball') }}
            />
          }
        />
      </AnimationWrapper>
    </PanelDisplay>
  );
});

export default MobileGifBox;
