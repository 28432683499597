import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Button from '@cof/graffiti-alley-spray-cans/molecules/Button';
import './QRCodeButton.scss';

const QRCodeButton = injectIntl(({ intl, qrCodeImage, buttonTextId, onClick }) => (
  <Button className="qr-code-button" onClick={onClick} size="small">
    <img src={qrCodeImage} alt="" />
    <span>{intl.formatMessage({ id: buttonTextId })}</span>
  </Button>
));

QRCodeButton.propTypes = {
  qrCodeImage: PropTypes.string.isRequired,
  buttonTextId: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

QRCodeButton.defaultProps = {};

export default QRCodeButton;
