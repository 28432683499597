import React from 'react';
import { FormattedMessage } from 'react-intl';
import { intlShape } from 'react-intl';

const AppPageLegalModalContent = ({ intl }) => (
  <div id="app-page-legal-content">
    <h2>
      <FormattedMessage id="modals.app-page-legal-modal.header" />
    </h2>

    {intl.locale === 'en' && (
      <p>
        <FormattedMessage id="modals.app-page-legal-modal.p1" />
      </p>
    )}

    <p>
      <FormattedMessage id="modals.app-page-legal-modal.p2" />
    </p>

    <p>
      <FormattedMessage id="modals.app-page-legal-modal.p3" />
    </p>
  </div>
);

AppPageLegalModalContent.propTypes = {
  intl: intlShape,
};

export default AppPageLegalModalContent;
